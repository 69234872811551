<template>
  <div class="chart-container">
    <p class="d-none">
      {{ this.chartData.series }}{{ this.chartOptions.xAxis }}
    </p>
    <line-chart :options="chartOptions" ref="lineChart"></line-chart>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
Highcharts.dateFormats = {
  q: function(timestamp) {
    var date = new Date(timestamp),
      quarter = Math.floor(date.getUTCMonth() / 3) + 1;
    return quarter;
  },
};
export default {
  name: "LineChart",
  components: {
    lineChart: Chart,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    boxshadow: {
      type: Boolean,
      required: false,
      default: false,
    },
    // bottomleft: {
    //   type: Boolean,
    // }
  },
  data() {
    return {
      chartOptions: {
        colors: this.chartData.colors,
        chart: {
          type: "line",
          width: this.chartData.width,
          height: this.chartData.height,
        },
        title: this.chartData.title,
        subtitle: this.chartData.subtitle,
        yAxis: this.chartData.yAxis,
        xAxis: this.chartData.xAxis,
        series: this.chartData.series,
        credits: {
          enabled: false,
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: false,
          },
        },
        legend: this.chartData.legend,
        tooltip: this.chartData.tooltip,
      },
    };
  },
  methods: {
    fetchNewData(series) {
      this.chartOptions.series = series;
    },
  },
  beforeUpdate() {
    this.fetchNewData(this.chartData.series);
    this.chartOptions.xAxis = this.chartData.xAxis;
  },
  created() {
    this.fetchNewData(this.chartData.series);
    this.chartOptions.xAxis = this.chartData.xAxis;
  },
};
</script>

<style scoped>
.chart-container {
  /* max-width: 1446px; */
  max-height: 588px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
  overflow: hidden;
  /* padding: 0 0 30px 0; */
  background: #fff;
}

.highcharts-markers {
  display: none;
}

.highcharts-root {
  padding: 0px;
}

.make-bold {
  font-weight: 900;
}
</style>
