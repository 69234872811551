import { HotifyApiHelper } from "./helper/HotifyApiHelper";
const apiHelper = new HotifyApiHelper();
export class AIDigitalPoem {
  constructor() {}
  postKpiData(data) {
    const uri = `process/v1/kpiselection`;
    return apiHelper.post(uri, data);
  }
  getStatus(uniqueId) {
    const uri = `process/v1/status?uniqueId=${uniqueId}`;
    return apiHelper.get(uri);
  }
  startPreProcess(data) {
    const uri = `process/v1/preprocessData`;
    return apiHelper.post(uri, data);
  }
  getAllModals(data) {
    const uri = `process/v1/modelsummary`;
    return apiHelper.post(uri, data);
  }
  startAttribution(uniqueId) {
    const uri = `process/v1/trainModel?uniqueId=${uniqueId}`;
    return apiHelper.get(uri);
  }
  getChartData(data, screenName, value) {
    const uri = `process/v1/modeloutput?screen=${screenName}&value=${value}`;
    return apiHelper.post(uri, data);
  }
  getOptimizedResult(data) {
    const uri = `aioptimise/screenres`;
    return apiHelper.post(uri, data);
  }
  startOptimization(uniqueId) {
    const uri = `process/v1/runOptimiser?uniqueId=${uniqueId}`;
    return apiHelper.get(uri);
  }
  getExpectedRevenue(uniqueId, growth, target) {
    const uri = `process/v1/optimisedResult?uniqueId=${uniqueId}&growth=${growth}&target=${target}&period=1`;
    return apiHelper.get(uri);
  }
  getDataFromScenario(data) {
    const uri = `process/v1/scenarioplanning`;
    return apiHelper.post(uri, data);
  }
  getAllModelsResult(clientId, rows, currentPage) {
    const uri = `process/v1/userHistory?clientId=${clientId}&rows=${rows}&currentPage=${currentPage}`;
    return apiHelper.get(uri);
  }
  getDataSufficent(orgNumber, country, data) {
    const uri = `process/v1/datacheck?clientId=${orgNumber}&country=${country}`;
    return apiHelper.post(uri, data);
  }
  saveModel(data) {
    const uri = `process/v1/saveModel`;
    return apiHelper.post(uri, data);
  }
  getSavedModels(clientId, rows, page) {
    const uri = `process/v1/getSavedModels?clientId=${clientId}&rows=${rows}&currentPage=${page}`;
    return apiHelper.get(uri);
  }
  deleteSavedModel(data){
    const uri = `process/v1/deleteModel`
    return apiHelper.post(uri,data)
  }
  saveOptimizationResult(data){
    const uri = `process/v1/saveOptimizationResults`
    return apiHelper.post(uri, data)
  }
  getSavedOptimizedResult(clientId,rows,currentPage){
    const uri = `process/v1/getOptimizationResults?clientId=${clientId}&rows=${rows}&currentPage=${currentPage}`
    return apiHelper.get(uri)
  }
  deletSavedOptimizedResult(id){
    const uri =`process/v1/deleteOptimizationResults?Id=${id}`
    return apiHelper.get(uri)
  }
  searchOptimiedResult(data){
    const uri =`process/v1/searchOptimizationResults`
    return apiHelper.post(uri,data)
  }
  searchModelResult(data){
    const uri =`process/v1/searchModel`
    return apiHelper.post(uri,data)
  }
}

