<template>
  <div>
    <b-pagination
      v-model="value"
      :per-page="perPage"
      pills
      align="right"
      hide-goto-end-buttons
      hide-ellipsis
      :total-rows="totalRows"
      class="mb-5"
      aria-controls="spaceTable"
      @change="getSelectedPage($event)"
    >
      <template #prev-text
        ><span
          ><img
            src="@/assets/Icons/arrow-left-line.svg"
            class="arrow-image"/></span
      ></template>
      <template #next-text
        ><span
          ><img
            src="@/assets/Icons/arrow-right-line.svg"
            class="arrow-image"/></span
      ></template>
      <template #page="{ page, active }">
        <span v-if="active">{{ page }}</span>
        <i v-else>{{ page }}</i>
      </template>
    </b-pagination>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalRows: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      value: this.currentPage,
    };
  },
  created() {
    this.value = this.currentPage;
  },
  beforeUpdate() {
    this.value = this.currentPage;
  },
  methods: {
    getSelectedPage(event) {
      this.$emit("getSelectedPageEvent", event);
    },
  },
};
</script>

<style>
.page-link {
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none !important;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #9aafd4;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  box-shadow: none !important;
}
ul.pagination.mb-5.b-pagination.justify-content-end.b-pagination-pills {
  justify-content: flex-start !important;
}
.input-group-prepend {
  background-color: #eff5ff;
}
.page-item.disabled .page-link {
  background-color: transparent;
}

.page-link i {
  font-style: normal;
}

.active > .page-link {
  background-color: transparent !important;
  border: 2px solid #050505 !important;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #222a37 !important;
  box-shadow: none;
}
</style>
