import { ApiHelper } from "./helper/ApiHelper";
const apiHelper = new ApiHelper();
export class BusinessAtGlance {
    constructor() {}
    getYearsForBusiness() {
        const uri = `health-monitor-module/v1/year?orgNumber=${sessionStorage.getItem(
      "subId"
    )}`;
        return apiHelper.get(uri);
    }
    getAdTypeData(data) {
        const uri = `health-monitor-module/v1/${data.name}?orgNumber=${data.orgNumber}&quarters=${data.quarters}&months=${data.months}&years=${data.years}&type=${data.type}&requiredMetrics=${data.metrics}`;
        return apiHelper.get(uri);
    }
    getTableDataAnalytics(data) {
        const uri = `health-monitor-module/v1/${data.name}?orgNumber=${data.orgNumber}&years=${data.years}&months=${data.months}&quarters=${data.quarters}&type=${data.type}&requiredMetrics=${data.metrics}&page=${data.page}&size=${data.size}`
        return apiHelper.get(uri)
    }
    getTrendData(name, orgNumber, year, month, quarter, type, metrics) {
        const uri = `health-monitor-module/v1/${name}?orgNumber=${orgNumber}&years=${year}&months=${month}&quarters=${quarter}&type=${type}&requiredMetrics=${metrics}`
        return apiHelper.get(uri)
    }
    getChannelCostAllocation(name, orgNumber, year, month, quarter, type, requiredMetrics) {
        const uri = `health-monitor-module/v1/${name}?orgNumber=${orgNumber}&years=${year}&months=${month}&quarters=${quarter}&type=${type}&requiredMetrics=${requiredMetrics}`
        return apiHelper.get(uri)
    }
    getValidationTable(orgNumber, year, month, quarters, type, metrics, page, size, isZero) {
        const uri = `health-monitor-module/v1/${type}?orgNumber=${orgNumber}&years=${year}&months=${month}&quarters=${quarters}&type=${type}&requiredMetrics=${metrics}&page=${page}&size=${size}&isZero=${isZero}`
        return apiHelper.get(uri)
    }
    getOverallData(orgNumber, year, month, quarter, type, requiredMetrics){
        const uri = `health-monitor-module/v1/performance?orgNumber=${orgNumber}&years=${year}&months=${month}&quarters=${quarter}&type=${type}&requiredMetrics=${requiredMetrics}`
        return apiHelper.get(uri)
    }
}