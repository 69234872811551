<template>
  <div>
    <multiselect
      ref="multiselect"
      v-model="value"
      track-by="name"
      label="name"
      placeholder="Select one"
      :options="options"
      :searchable="false"
      :allow-empty="false"
      :show-labels="false"
      @select="selectValue($event)"
      class="singleRadio"
    >
      <template slot="option" slot-scope="props">
        <span
          class="option__desc"
          :for="props.option.name + props.option.value"
        >
          <input
            type="radio"
            :id="props.option.name + props.option.value"
            :checked="props.option.name === value.name"
          />
          <span class="option__title ml-2">{{ props.option.name }}</span></span
        >
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: ["options", "value"],
  components: {
    Multiselect,
  },
  data() {
    return {
      selectOption: this.value,
      //   options:[{ name: "Vue.js", value: "JavaScript"},
      //   { name: "Adonis", value: "JavaScript"},
      //   { name: "Rails", value: "Ruby"},
      //   { name: "Sinatra", value: "Ruby"},
      //   { name: "Laravel", value: "PHP"},
      //   { name: "Phoenix", value: "Elixir"},],
      // value: { name: "Adonis", value: "JavaScript"},
      // options: [
      //],
    };
  },
  methods: {
    selectValue(event) {
      this.$emit("selectedOptions", event);
    },
  },
  beforeUpdate() {
    this.selectOption = this.value;
  },
  created() {
    this.selectOption = this.value;
  },
};
</script>

<style>
.singleRadio.multiselect {
  box-shadow: 0 0 8px 0 #c9ced5;
  border-radius: 3px;
  min-height: 55px;
  min-width: 230px;
  z-index: 10 !important;
}
.singleRadio .multiselect__content-wrapper {
  background-color: #fff;
  box-shadow: 0px 5px 5px 0px #c9ced5;
  top: 55px;
}
.multiselect__single {
  width: auto;
  min-height: 55px;
  border: 0px solid;
  margin: 0;
  font-size: 12px;
  background: #fff;
  font-family: ProximaNovaBold;
  color: #313846;
  font-size: medium;
  padding: 10px;
  padding-right: 0px;
  align-items: center;
  display: flex;
}
.singleRadio .multiselect__tags {
  min-height: 0px;
  padding: 0px;
  background: #fff;
  color: #313846;
  display: flex;
}
.multiselect__select:before {
  border-color: #313846 transparent transparent;
}
.multiselect__select {
  height: 40px;
  padding: 0px;
}
.multiselect__input {
  background: transparent;
}
.multiselect__placeholder {
  padding-left: 5px;
  padding-top: 0px;
  margin: 0px;
  font-size: 13px;
}
.singleRadio .multiselect__option {
  display: flex;
  line-height: 6px;
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #313846;
  font-family: ProximaNovaRegular;
}
.singleRadio .multiselect__option:hover {
  background: #eff5ff !important;
  font-family: ProximaNovaBold;
}
.singleRadio .multiselect__option--selected,
.singleRadio .multiselect__option--group {
  background: #eff5ff !important;
  font-weight: 400;
  cursor: pointer;
  color: #313846;
  font-family: ProximaNovaBold;
}
.singleRadio .multiselect__option--selected {
  color: #313846;
}
.singleRadio
  .multiselect__option--selected.multiselect__option--highlight
  > label
  > span {
  color: #313846;
  opacity: 0.8;
}
.singleRadio .multiselect__option--selected.multiselect__option--highlight {
  color: #313846;
}
.singleRadio .multiselect__option--highlight > label > span {
  opacity: 0.8;
}
.singleRadio .multiselect__option--disabled {
  color: #989898;
}
label {
  cursor: pointer;
}
</style>
